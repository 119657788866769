import { ethers } from "ethers";
import abi from "./abi.json";
import data from "./address.json";
import dotenv from "dotenv";
dotenv.config();
const ContractAddress = data.address;
const ContractAbi = abi.abi;
import { setApprovalForAll } from "./nfts";
//const toWei = (num) => ethers.parseEther(num.toString());
const toWei = (amount, decimals = 18) =>
  ethers.parseEther(amount.toFixed(decimals), decimals);

const fromWei = (num) => ethers.formatEther(num);
function toWeiManual(amount, decimals = 18) {
  const factor = 10 ** decimals; // Equivalent to 10^decimals
  return (amount * factor).toFixed(0); // Multiply and convert to a string without decimal places
}

// import { CovalentClient } from "@covalenthq/client-sdk";

// const ApiServices = async () => {
//     try {
//         // Initialize the CovalentClient with your API key
//         const client = new CovalentClient("cqt_rQQgjMwbGPgd9yKxQVp9GX4bJd99");

//         // Fetch NFT transactions for a specific contract and token ID
//         const resp = await client.NftService.getNftTransactionsForContractTokenId(
//             "eth-sepolia", // Network
//             "0xE203eB12530f4cd430E3aA25D9653c8E764021fa", // Contract Address
//             "0" // Token ID
//         );

//         // Log the response data
//         console.log(resp.data);
//     } catch (error) {
//         // Handle errors
//         console.error('Error fetching NFT transactions:', error);
//     }
// };

// // Call the ApiServices function
// ApiServices();

const getEthereumContract = async () => {
  const provider = new ethers.BrowserProvider(ethereum);
  const accounts = await provider.send("eth_requestAccounts", []);
  const balance = await provider.getBalance(accounts[0]);
  const signer = await provider.getSigner();
  const transactionContract = new ethers.Contract(
    ContractAddress,
    ContractAbi,
    signer
  );
  return transactionContract;
};

const sendEther = async (amount, reciever_Address) => {
  const provider = new ethers.BrowserProvider(ethereum);
  const signer = await provider.getSigner();
  const tx = await signer.sendTransaction({
    to: reciever_Address,
    value: ethers.parseUnits(amount, "ether"),
  });
  console.log(tx);
  return tx;
};

const getCurrentAccount = async () => {
  try {
    const provider = new ethers.BrowserProvider(ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    return accounts[0];
  } catch (error) {
    console.error("Error fetching account hash:", error);
    throw error;
  }
};

const getBalance = async () => {
  try {
    const provider = new ethers.BrowserProvider(ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    const balance = await provider.getBalance(accounts[0]);
    return balance;
  } catch (error) {
    console.error("Error fetching account balance:", error);
    throw error;
  }
};

const createListing = async (token_id, price, amount) => {
  try {
    console.log("Fetching contract...");
    const contract = await getEthereumContract();
    console.log("Contract:", contract);

    if (!contract) {
      console.error("Contract not found.");
      return null;
    }

    console.log("the contract is found");

    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    console.log("Accounts:", accounts);
    console.log("Parameters:", token_id, price, amount);
    const offerPrice = price * amount;
    console.log(offerPrice);
    const total_price = toWeiManualManual(offerPrice);
    console.log(total_price);
    const tx = await contract.createListing(token_id, total_price, amount, {
      from: accounts[0],
    });
    console.log("Transaction:", tx);

    const receipt = await tx.wait();
    console.log("Receipt:", receipt);

    return receipt;
  } catch (error) {
    console.error("Error:", error);
    const parsedError = JSON.parse(JSON.stringify(error));
    console.error("Parsed Error:", parsedError?.reason);
    window.alert(parsedError?.reason);
    return null;
  }
};

const getListing = async (token_id, owner_address) => {
  // console.log(token_id, owner_address);
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const result = await contract.getListing(token_id, owner_address, {
      from: accounts[0],
    });
    const formattedListing = {
      tokenId: result[0].toString(),
      seller: result[1],
      marketPlace: result[2],
      amount: result[3].toString(),
      price: result[4].toString(),
      offerer: result[5],
      currentOffer: result[6].toString(),
      isListed: result[7],
    };
    // console.log(formattedListing);
    if (result[7] == "false") {
      return "not listed";
    } else {
      return formattedListing;
    }
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
  }
};

const cancelListing = async (token_id, owner_address) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    await contract.cancelListing(token_id, owner_address, {
      from: accounts[0],
    });
    return "Listing cancelled";
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
    console.log(error);
  }
};

const purchaseListing = async (
  token_id,
  owner_address,
  amount,
  royalty,
  creator_address
) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });

    // Convert royalty percentage to decimal
    const royaltyDecimal = Number(royalty) / 100;

    // Validate and convert amounts
    const amountInWei = parseFloat(amount);
    if (isNaN(amountInWei) || amountInWei <= 0) {
      throw new Error("Invalid amount");
    }

    // Calculate amounts and convert to Wei with appropriate precision
    const royaltyAmount = toWeiManual(amountInWei * royaltyDecimal);
    const sellerAmount = toWeiManual(amountInWei);

    // Log the amounts
    console.log("Royalty Amount (Wei):", royaltyAmount.toString());
    console.log("Seller Amount (Wei):", sellerAmount.toString());

    // Call purchaseListing and pass the amount in wei
    const check = await setApprovalForAll(accounts[0]);
    if (check) {
      const tx = await contract.purchaseListing(
        token_id,
        owner_address,
        royaltyAmount,
        creator_address,
        {
          value: sellerAmount,
          from: accounts[0],
        }
      );

      console.log("Transaction:", tx);

      // Wait for the transaction receipt
      const receipt = await tx.wait();
      console.log("Receipt:", receipt);

      return "Purchased successfully";
    } else {
      return "error";
    }
  } catch (error) {
    console.error("Error:", error);
    const errorReason =
      JSON.parse(JSON.stringify(error))?.reason || error.message;
    // reportError(errorReason);
    // window.alert(errorReason);
    console.log("error", errorReason);
  }
};

const makeOffer = async (token_id, owner_address, offerPrice, supply) => {
  try {
    console.log(token_id, owner_address, offerPrice, supply);
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const price = offerPrice * supply;
    console.log(price);
    const amount = toWeiManual(price);
    console.log(amount);
    const check = await setApprovalForAll(accounts[0]);
    const make_offer = await contract.makeOffer(token_id, owner_address, {
      from: accounts[0],
      value: amount,
    });
    console.log(make_offer);
    return "offer made successfully";
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
    console.log("error", error);
  }
};

const cancelOffer = async (token_id, owner_address) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    await contract.cancelOffer(token_id, owner_address, { from: accounts[0] });
    return "offer cancelled";
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
    console.log("error", error);
  }
};

const acceptOffer = async (
  token_id,
  owner_address,
  amount,
  royalty,
  creator_address
) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });

    // Convert royalty percentage to decimal
    const royaltyDecimal = Number(royalty) / 100;

    // Validate and convert amounts
    const amountInWei = parseFloat(amount);
    if (isNaN(amountInWei) || amountInWei <= 0) {
      throw new Error("Invalid amount");
    }

    // Calculate amounts and convert to Wei with appropriate precision
    const royaltyAmount = toWeiManual((amountInWei * royaltyDecimal) / 1e18);
    const sellerAmount = toWeiManual(amountInWei / 1e18);

    // Log the amounts
    console.log("Royalty Amount (Wei):", royaltyAmount.toString());
    console.log("Seller Amount (Wei):", sellerAmount.toString());
    // Call acceptOffer, passing in the royalty amount and necessary arguments
    await contract.acceptOffer(
      token_id,
      owner_address,
      royaltyAmount,
      creator_address,
      {
        from: accounts[0],
      }
    );

    return "Offer accepted successfully";
  } catch (error) {
    const errorReason = JSON.parse(JSON.stringify(error))?.reason;
    // reportError(errorReason);
    // window.alert(errorReason);
    console.log("error", error);
  }
};

const createAuction = async (token_id, amount, startingPrice, duration) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const offerPrice = startingPrice * amount;
    console.log(offerPrice);
    const total_price = toWeiManual(offerPrice);
    console.log(total_price);
    const check = await setApprovalForAll(accounts[0]);
    const tx = await contract.createAuction(
      token_id,
      amount,
      total_price,
      duration,
      accounts[0],
      { from: accounts[0] }
    );
    return "auction created successfully:", tx;
  } catch (error) {
    console.error(error);
    alert("Some error occured while creating auction");
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
  }
};

const cancelAuction = async (token_id, owner_address) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const tx = await contract.cancelAuction(token_id, owner_address, {
      from: accounts[0],
    });
    return "auction cancelled:", tx;
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
    console.log("error", error);
  }
};
const placeBid = async (token_id, owner_address, bidPrice) => {
  console.log(token_id, owner_address, bidPrice);
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const check = await setApprovalForAll(accounts[0]);
    const tx = await contract.placeBid(token_id, owner_address, {
      value: ethers.parseEther(bidPrice),
      from: accounts[0],
    });
    return "bid is placed:", tx;
  } catch (error) {
    // reportError(JSON.parse(JSON.stringify(error))?.reason);
    // window.alert(JSON.parse(JSON.stringify(error))?.reason);
    console.log("error", error);
  }
};

const claimAuction = async (
  token_id,
  owner_address,
  amount,
  royalty,
  creator_address
) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });

    // Convert royalty percentage to decimal
    const royaltyDecimal = Number(royalty) / 100;

    // Validate and convert amounts
    const amountInWei = parseFloat(amount);
    if (isNaN(amountInWei) || amountInWei <= 0) {
      throw new Error("Invalid amount");
    }

    // Calculate amounts and convert to Wei with appropriate precision
    const royaltyAmount = toWeiManual((amountInWei * royaltyDecimal) / 1e18);
    const sellerAmount = toWeiManual(amountInWei / 1e18);

    // Log the amounts
    console.log("Royalty Amount (Wei):", royaltyAmount.toString());
    console.log("Seller Amount (Wei):", sellerAmount.toString());

    // Call claimAuction, passing in the royalty amount and necessary arguments
    const tx = await contract.claimAuction(
      token_id,
      owner_address,
      royaltyAmount,
      creator_address,
      {
        from: accounts[0],
      }
    );

    return "Auction claimed and ended:", tx;
  } catch (error) {
    const errorReason = JSON.parse(JSON.stringify(error))?.reason;
    // reportError(errorReason);
    // window.alert(errorReason);
    console.log("error", errorReason);
  }
};

const getAuctions = async (token_id, owner_address) => {
  try {
    const contract = await getEthereumContract();
    const accounts = await ethereum?.request?.({
      method: "eth_requestAccounts",
    });
    const tx = await contract.auctions(token_id, owner_address, {
      from: accounts[0],
    });
    const auctionEndTime = tx[7].toString(); // Unix timestamp

    // Convert the timestamp to milliseconds and create a Date object
    const date = new Date(auctionEndTime * 1000);
    const duration = date - new Date();

    // Convert to Indian Standard Time (UTC+5:30)
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const indianTime = date.toLocaleString("en-IN", options);

    // console.log("Auction End Time in IST:", indianTime);

    const auctionDetails = {
      tokenId: tx[0].toString(),
      seller: tx[1],
      marketPlace: tx[2],
      amount: tx[3].toString(),
      startingPrice: tx[4].toString(),
      currentBid: tx[5].toString(),
      highestBidder: tx[6],
      duration,
      isActive: tx[8],
    };
    return auctionDetails;
  } catch (error) {
    console.error(error);
  }
};

export {
  getEthereumContract,
  sendEther,
  createListing,
  getListing,
  cancelListing,
  purchaseListing,
  makeOffer,
  cancelOffer,
  acceptOffer,
  createAuction,
  cancelAuction,
  placeBid,
  claimAuction,
  getCurrentAccount,
  getBalance,
  getAuctions,
};

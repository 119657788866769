import "../styles/globals.css";
import "../styles/index.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import { useState, useEffect } from "react";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import SubNav from "../components/subNav";
import { EthereumProvider } from "../context/contract";
import { WalletProvider } from "../components/wallet-btn/WalletProvider";
import Link from "next/link";
import Router from "next/router";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });
  // Define the paths where you want the button to appear
  const buttonVisiblePaths = ["/home/home_1"];
  const isPathMatching = (path) => {
    if (path === "/home/home_1") return true;
    return false;
  };

  const [currentPath, setCurrentPath] = useState(router.pathname);
  const showButton = isPathMatching(currentPath);

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentPath(router.pathname);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, router.pathname]);

  const shouldNoLayout = ["/login", "/", "/signup"];

  return (
    <>
      <Meta title="Home 1" />
      <WalletProvider>
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <EthereumProvider>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                  {shouldNoLayout.includes(pid) ? (
                    <Component {...pageProps} />
                  ) : (
                    <Layout>
                      <Component {...pageProps} />
                      {showButton && (
                        <div className="fixed z-50 bottom-8 ml-auto -left-1/2 -right-1/2 text-center">
                          <Link
                            href="/CreateMain"
                            className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-2 px-20 text-center text-sm font-semibold text-white transition-all btn-grad"
                            //  className="bg-accent font-bold text-md tracking-wider border-2 py-3 px-20 rounded-full border-none text-white"
                          >
                            Create
                          </Link>
                        </div>
                      )}
                    </Layout>
                  )}
                </UserContext.Provider>
              </EthereumProvider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
      </WalletProvider>
    </>
  );
}

export default MyApp;

// protected routes
//  - /createGrocery
//  - /createProperty
//  - /createProduct
//  - /createMain
//  - /createProfile
//  - /dashboard
//  - /live-auction
// - /login
// - /maintenance
// - /nftPage
// - /profile
// - /property
// - /rankings
// - /user

// public routes
// - /404
// - /blog
// - /careers
// -/case-studies
// -/collection
// - /comingsoon
// - /contact
// - /dao
// - /faq
// - /Ecommerce
// - /help - center
// - /home
// - /item
// - /landing
// - /newsletter
// - /partners
// - /platform_status
// - /pricing
// - /prop
// /single
// -/post
// - /tarms
// -/wallet

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bidsModalHide } from "../../redux/counterSlice";
import { placeBid } from "../../services/services";
import { getBalance } from "../../services/services";
import { createTransaction } from "../../utils/dbFunctions";
import axios from "axios";

const BidsModal = () => {
  const { bidsModal, bidsModalData } = useSelector((state) => state.counter);
  const [checkTerms, setCheckTerms] = useState(false);
  const dispatch = useDispatch();
  const [USDAmount, setUSDAmount] = useState(null); // Initialize without convert function
  const [ETHAmount, setETHAmount] = useState("");
  const [USDToethRate, setUSDToethRate] = useState(null);

  const roundToDecimalPlaces = (num, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    setETHAmount(e.target.value);
    setUSDAmount(convertEthToUsd(e.target.value));
  };

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd"
        );
        const data = response.data;
        const rates = data["matic-network"].usd;

        setUSDToethRate(rates);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };
    fetchExchangeRate();
  }, []);

  const convertEthToUsd = (eth) => {
    return (eth * USDToethRate).toFixed(2);
  };

  const [accountBalance, setAccountBalance] = useState(null);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const data = await getBalance();
        const divisor = 10n ** 18n;
        const result = Number(data) / Number(divisor);
        const roundedResult = result.toFixed(4);
        setAccountBalance(roundedResult);
      } catch (error) {
        console.error("Failed to fetch account:", error);
      }
    };
    fetchBalance();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkTerms) {
      alert("Please agree to the terms");
      return;
    }

    if (!ETHAmount || ETHAmount <= bidsModalData?.price) {
      alert("Bid amount must be greater than the current price");
      return;
    }

    try {
      const response = await placeBid(
        bidsModalData.token_id,
        bidsModalData.ownerAddress,
        ETHAmount
      );
      console.log(response);
      if (response) {
        await createTransaction(
          "bids",
          bidsModalData.token_id,
          "Bid placed for " + ETHAmount + " ETH",
          "Bid placed by " +
            bidsModalData.bidder.slice(0, 10) +
            " for " +
            ETHAmount +
            " ETH",
          bidsModalData.bidder,
          bidsModalData.ownerAddress,
          bidsModalData.amount,
          roundToDecimalPlaces(ETHAmount, 4),
          "place"
        );
        alert("Bid placed successfully");
        dispatch(bidsModalHide());
      }
    } catch (error) {
      console.error("Error placing bid:", error);
    }
  };
  return (
    <div>
      {/* {USDAmount} {ETHAmount} */}
      <div className={bidsModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Place a bid
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(bidsModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 flex items-center justify-between">
                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Price
                </span>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                <div className="border-jacarta-100 bg-jacarta-50 flex flex-1 items-center self-stretch border-r px-2">
                  <span>
                    <svg className="icon icon-ETH mr-1 h-5 w-5">
                      <use xlinkHref="/icons.svg#icon-ETH"></use>
                    </svg>
                  </span>
                  <span className="font-display text-jacarta-700 text-sm">
                    ETH
                  </span>
                </div>

                <input
                  type="number"
                  step={0.0001}
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder={`Amount ${ETHAmount}`}
                  value={ETHAmount}
                  onChange={(e) => handleEThAmount(e)}
                />

                <div className="bg-jacarta-50 border-jacarta-100 flex flex-1 justify-end self-stretch border-l dark:text-jacarta-700">
                  <span className="self-center px-2 text-sm">${USDAmount}</span>
                </div>
              </div>

              <div className="text-right">
                <span className="dark:text-jacarta-400 text-sm">
                  Balance: {accountBalance} WETH
                </span>
              </div>

              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  value={checkTerms}
                  onChange={() => setCheckTerms(!checkTerms)}
                  className="checked:bg-accent dark:bg-[#1e1e1e] text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />
                <label
                  htmlFor="terms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"nftos's"}{" "}
                  <a href="#" className="text-accent">
                    Terms of Service
                  </a>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  Place Bid
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BidsModal;

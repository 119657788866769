import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";

const footer = () => {
  return (
    <div className="w-[100vw] relative h-[220px]">
      <footer className="w-full h-full page-footer bg-[black] rounded-t-[1000px] absolute top-0 left-0">
        <div className="container w-full">
          <div className="flex flex-col items-center justify-between py-8 gap-4">
            <img src="/logo.png" alt="" className="w-[120px]" />

            <ul className="flex gap-4 invert">
              <a href="">
                <img src="/twitter.svg" alt="" className="text-white w-8" />
              </a>
              <a href="">
                <img src="/linkedin.svg" alt="" className="w-8" />
              </a>
            </ul>
            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  © 2024 Trillicorn Technology
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default footer;

import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import popupItemReducer from './popupItemSlice';
import itemsReducer from './itemDataSlice'
import dashReducer from './dashSlice'

export const store = configureStore({
	reducer: {
		counter:counterReducer,
		popupItems: popupItemReducer,
		items:itemsReducer,
		dash:dashReducer,
	},
});

import { createSlice } from "@reduxjs/toolkit";
import { te } from "date-fns/locale";
import { act } from "react";

const initialState = {
  mblMenu: false,
  dropdown: false,
  collection_activity_item_data: [],
  trendingCategoryItemData: [],
  sortedtrendingCategoryItemData: [],
  collectiondata: [],
  sortedCollectionData: [],
  renkingData: [],
  filteredRenkingData: [],
  walletModal: false,
  bidsModal: false,
  bidsModalData: null,
  buyModalData: null,
  buyModal: false,
  propartiesModalValue: false,
  trendingCategorySorText: "",
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    openMblMenu: (state) => {
      state.mblMenu = true;
    },
    closeMblMenu: (state) => {
      state.mblMenu = false;
    },

    openDropdown: (state) => {
      state.dropdown = true;
    },
    closeDropdown: (state) => {
      state.dropdown = false;
    },
    handle_collection_activity_item_data: (state, payload) => {
      state.collection_activity_item_data = payload.data;
    },
    walletModalShow: (state) => {
      state.walletModal = true;
    },
    walletModalhide: (state) => {
      state.walletModal = false;
    },
    bidsModalShow: (state, action) => {
      state.bidsModal = true;
      state.bidsModalData = action.payload;
    },
    bidsModalHide: (state) => {
      state.bidsModal = false;
      state.bidsModalData = null;
    },
    buyModalShow: (state, action) => {
      state.buyModal = true;
      state.buyModalData = action.payload;
    },
    buyModalHide: (state) => {
      state.buyModal = false;
      state.buyModalData = null;
    },
    showPropatiesModal: (state) => {
      state.propartiesModalValue = true;
    },
    closePropatiesModal: (state) => {
      state.propartiesModalValue = false;
    },
    updateTrendingCategoryItemData: (state, action) => {
      state.trendingCategoryItemData = action.payload;
      state.sortedtrendingCategoryItemData = action.payload;
    },
    updatetrendingCategorySorText: (state, action) => {
      const sortText = action.payload;
      if (sortText === "Price: Low to High") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => a.sortPrice - b.sortPrice
          );
      } else if (sortText === "Price: high to low") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => b.sortPrice - a.sortPrice
          );
      } else if (sortText === "Recently Added") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => a.addDate - b.addDate);
      } else if (sortText === "Auction Ending Soon") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => b.addDate - a.addDate);
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    updateTrendingCategoryItemByInput: (state, action) => {
      const text = action.payload;
      if (text === "Verified Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.verified;
          });
      } else if (text === "NFSW Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.nfsw;
          });
      } else if (text === "Show Lazy Minted") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.lazyMinted;
          });
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    collectCollectionData: (state, action) => {
      const data = action.payload;
      state.collectiondata = data;
      state.sortedCollectionData = data;
    },
    updateCollectionData: (state, action) => {
      const text = action.payload;
      console.log(text);
      if (text === "trending") {
        const tampItem = state.collectiondata.filter((item) => item.trending);
        state.sortedCollectionData = tampItem;
      }
      if (text === "top") {
        const tampItem = state.collectiondata.filter((item) => item.top);
        state.sortedCollectionData = tampItem;
      }
      if (text === "recent") {
        const tampItem = state.collectiondata.filter((item) => item.recent);
        state.sortedCollectionData = tampItem;
      }
      // state.sortedCollectionData = state.collectiondata;
    },
    updateCollectionDataByBlockchain: (state, action) => {
      const text = action.payload;
      let tempItem = state.collectiondata.filter(
        (item) => item.blockchain === text
      );
      if (text === "All") {
        tempItem = state.collectiondata;
      }
      state.sortedCollectionData = tempItem;
    },
    updateCollectionMarketData: (state, action) => {
      const id = action.payload;
      let tempItem;
      if (id === 2) {
        tempItem = state.collectiondata.filter((item) => item.createProduct);
      } else if (id === 3) {
        tempItem = state.collectiondata.filter((item) => item.createProperty);
      } else {
        tempItem = state.collectiondata;
      }
      state.sortedCollectionData = tempItem;
    },
    updateCollectionSaleType: (state, action) => {
      const id = action.payload;
      let tempItem;
      if (id === 1) {
        tempItem = state.collectiondata.filter((item) => item.isActive); // auctions
      } else if (id === 2) {
        tempItem = state.collectiondata.filter(
          (item) => item.isListed && item.listingType === "direct"
        ); // direct buy
      } else if (id === 3) {
        tempItem = state.collectiondata.filter(
          (item) => item.isListed && item.listingType === "offer"
        ); // make offer
      } else if (id === 4) {
        tempItem = state.collectiondata.filter(
          (item) => !item.isListed && !item.isActive
        ); // not listed or auctioned
      } else {
        tempItem = state.collectiondata; // all
      }
      state.sortedCollectionData = tempItem;
    },
    updateCollectionPriceData: (state, action) => {
      const { from, to } = action.payload;
      let tempItem;
      if (from < to && from >= 0 && to >= 0) {
        tempItem = state.collectiondata.filter(
          (item) => Number(item.price) >= from && Number(item.price) <= to
        );
      } else {
        tempItem = state.collectiondata;
      }
      state.sortedCollectionData = tempItem;
    },
    updateCollectionDataByCategory: (state, action) => {
      const text = action.payload;
      let tempItem = state.collectiondata.filter(
        (item) => item.category_type.toLowerCase() === text.toLowerCase()
      );
      if (text.toLowerCase() === "all") {
        tempItem = state.collectiondata;
      }
      state.sortedCollectionData = tempItem;
    },
    updateCollectionDataByTag: (state, action) => {
      const text = action.payload;
      let tempItem = state.collectiondata.filter(
        (item) => item.category_tag?.toLowerCase() === text.toLowerCase()
      );
      if (text.toLowerCase() === "all") {
        tempItem = state.collectiondata;
      }
      state.sortedCollectionData = tempItem;
    },
    updateRecentCollectionData: (state, action) => {
      const value = action.payload;
      let tempItem;
      if (value === "recently_added") {
        tempItem = state.sortedCollectionData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else if (value === "price_low_high") {
        tempItem = state.sortedCollectionData.sort((a, b) => a.price - b.price);
      } else if (value === "price_high_low") {
        tempItem = state.sortedCollectionData.sort((a, b) => b.price - a.price);
      } else if (value === "ending_soon") {
        tempItem = state.sortedCollectionData.sort(
          (a, b) => b.auctionDuration - a.auctionDuration
        );
      } else {
        tempItem = state.sortedCollectionData;
      }
      state.sortedCollectionData = tempItem;
    },

    collectRenkingData: (state, action) => {
      state.renkingData = action.payload;
      state.filteredRenkingData = action.payload;
    },
    updateRenkingData: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter((item) => item.category === text);
      if (text === "All") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    updateRenkingDataByBlockchain: (state, action) => {
      const id = action.payload ? action.payload.toLowerCase() : null; // Check if payload exists
      let tempItem;
    
      if (id === "ethereum") {
        tempItem = state.renkingData.filter(
          (item) => item.blockchain && item.blockchain.toLowerCase() === "ethereum"
        );
      } else if (id === "flow") {
        tempItem = state.renkingData.filter(
          (item) => item.blockchain && item.blockchain.toLowerCase() === "flow"
        );
      } else if (id === "fusd") {
        tempItem = state.renkingData.filter(
          (item) => item.blockchain && item.blockchain.toLowerCase() === "fusd"
        );
      } else {
        tempItem = state.renkingData; // Show all if no specific blockchain is selected
      }
    
      state.filteredRenkingData = tempItem;
    },
    
    updateRenkingDataByPostdate: (state, action) => {
      const text = action.payload;
      const currentTime = new Date();
      let duration = 0;
    
      switch (text) {
        case "Last 7 Days":
          duration = 7;
          break;
        case "Last 14 Days":
          duration = 14;
          break;
        case "Last 30 Days":
          duration = 30;
          break;
        case "Last 60 Days":
          duration = 60;
          break;
        case "Last 90 Days":
          duration = 90;
          break;
        case "Last Year":
          duration = 365;
          break;
        default:
          duration = null;
      }
    
      let tempItem = state.renkingData;
    
      if (duration) {
        tempItem = state.renkingData.filter((item) => {
          const postDate = new Date(item.createdAt);
          const diffInDays = (currentTime - postDate) / (1000 * 60 * 60 * 24);
          return diffInDays <= duration;
        });
      }
    
      state.filteredRenkingData = tempItem;
    },
    
    updateRenkingMarketData: (state, action) => {
      const id = action.payload.toLowerCase();
      let tempItem;
      if (id === "virtual") {
        tempItem = state.renkingData.filter(
          (item) => item.market.toLowerCase() === "virtual"
        );
      } else if (id === "realestate") {
        tempItem = state.renkingData.filter(
          (item) => item.market.toLowerCase() === "realestate"
        );
      } else {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openMblMenu,
  closeMblMenu,
  openDropdown,
  closeDropdown,
  walletModalShow,
  walletModalhide,
  bidsModalShow,
  bidsModalHide,
  buyModalShow,
  buyModalHide,
  showPropatiesModal,
  closePropatiesModal,
  updatetrendingCategorySorText,
  updateTrendingCategoryItemData,
  updateTrendingCategoryItemByInput,
  collectCollectionData,
  updateCollectionData,
  updateCollectionDataByBlockchain,
  updateCollectionMarketData,
  updateCollectionSaleType,
  updateCollectionPriceData,
  updateCollectionDataByCategory,
  updateCollectionDataByTag,
  updateRecentCollectionData,
  collectRenkingData,
  updateRenkingData,
  updateRenkingDataByBlockchain,
  updateRenkingDataByPostdate,
  updateRenkingMarketData,
} = counterSlice.actions;

export default counterSlice.reducer;

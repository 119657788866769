import { N } from "ethers";

const createTransaction = async (
  eventName,
  token,
  senderResponse,
  receiverResponse,
  walletFrom,
  walletTo,
  quantity,
  price,
  action = ""
) => {
  try {
    const response = await fetch("/api/transactions/save-trans", {
      method: "POST",
      body: JSON.stringify({
        eventName,
        tokenId: token,
        senderResponse,
        receiverResponse,
        senderWallet: walletFrom,
        receiverWallet: walletTo,
        quantity,
        price,
        action,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Nft saved successfully");
    } else {
      throw new Error(data.message || "Something went wrong");
    }
  } catch (error) {
    alert("Failed to save transaction: " + error.message);
  }
};
const createProperty = async (
  tokenId,
  nftName,
  nftImage,
  collectionName,
  creatorWallet
) => {
  try {
    const response = await fetch("/api/nfts/create-realstate", {
      method: "POST",
      body: JSON.stringify({
        token_id: tokenId,
        nftName,
        nftImage,
        collectionName,
        creatorWallet,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Property craeted successfully");
    } else {
      throw new Error(data.message || "Something went wrong");
    }
  } catch (error) {
    setError("Failed to create NFT: " + error.message);
  }
};

const createNFT = async (
  token_id,
  nftName,
  nftImage,
  collectionName,
  creatorWallet
) => {
  try {
    const response = await fetch("/api/nfts/create-virtual", {
      method: "POST",
      body: JSON.stringify({
        token_id,
        nftName,
        nftImage,
        collectionName,
        creatorWallet,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Nft craeted successfully");
    } else {
      throw new Error(data.message || "Something went wrong");
    }
  } catch (error) {
    setError("Failed to create NFT: " + error.message);
  }
};

const updateListingType = async (pid, listingType) => {
  try {
    const response = await fetch("/api/nfts/update-listing-type", {
      method: "PUT",
      body: JSON.stringify({
        pid,
        listingType,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Listing type updated successfully");
    } else {
      throw new Error(data.message || "Something went wrong");
    }
  } catch (error) {
    console.error("Failed to update listing type:", error);
  }
};

export { createTransaction, createProperty, createNFT, updateListingType };
